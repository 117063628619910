<template>
  <div class="px-5 mb-8 top" id="page-todowizard">

    <b-modal v-model="showStepModal" ref="todo-step-modal" hide-footer title="">
      <div class="d-block text-center">
        <h3></h3>
      </div>
      <b-button class="mt-3" variant="outline-success" block @click="nextPage">{{ $t('COMMON.OK') }}</b-button>
      <b-button class="mt-2" variant="outline-warning" block @click="resumePage">Cancel</b-button>
    </b-modal>
    <h4 class="mb-8 ml-2 mt-8">{{ $t('TODO.TODO_WIZARD_HEADER') }}</h4>

    <b-card title="" class="mb-2" hide-footer>
      <!--begin: Wizard-->
      <div
        class="wizard wizard-3"
        id="kt_wizard_v3"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Nav -->
        <div class="wizard-nav border-bottom mb-1 mb-lg-5">
          <div class="wizard-steps px-8 py-8 px-lg-15 py-lg-3">
            <template v-for="(item, i) in pages">
              <div
                class="wizard-step"
                v-bind:key="i"
                data-wizard-type="step"
                v-bind:data-wizard-state="item.state"
              >
                <div class="wizard-label">
                  <h3 class="wizard-title">
                    <span>{{ i + 1 }}</span> {{ i === 0 ? 'Introduktion' : item.name }}
                  </h3>
                  <div class="wizard-bar"></div>
                </div>
              </div>
            </template>

            <div class="wizard-step" data-wizard-type="step" data-wizard-state="pending">
              <div class="wizard-label">
                <h3 class="wizard-title">
                  <span>{{ pages.length + 1 }}</span> Klart
                </h3>
                <div class="wizard-bar"></div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body-->
        <div class="row justify-content-center px-8 px-lg-10">
          <div class="col-xl-12 col-xxl-7">
            <!--begin: Wizard Form-->
            <form class="form" id="kt_form" @submit.prevent="">
              <template v-for="(page, i) in pages">
                <div
                  class="pb-5 pt-5"
                  v-bind:key="i"
                  data-wizard-type="step-content"
                  v-bind:data-wizard-state="page.state"
                >
                
                  <!--<h4 class="mb-10 font-weight-bold text-dark">{{ page.name }}</h4>-->
                  <IntroPage v-if="i === 0" :todo="todo" :task="page.task" />

                  <TodoWizardPage
                    v-else
                    :page="page"
                    :todo="todo"
                    :task="page.task"
                    @fileUploaded="fileUploaded"
                    @updated="task_updated"
                    @disableNext="
                      val => {
                        disabledNextButton = val;
                      }
                    "
                  />
                </div>
              </template>

              <!-- Begin: Final confirm step -->
              <div class="pb-5" data-wizard-type="step-content" data-wizard-state="pending">
                <b-row align-v="center" style="height: 300px">
                  <b-col align="center">
                    <h2 class="mb-8">{{ $t('COMMON.THANK_YOU') }}</h2>
                    <h4 class="font-weight-bold text-dark">{{ $t('TODO.TODO_WIZARD_COMPLETED') }}</h4>
                  </b-col>
                </b-row>
              </div>
              <!-- End: Final confirm step -->

              <!--begin: Wizard Actions -->
              <div class="d-flex justify-content-between border-top pt-16">
                <div class="mr-2">
                  <button
                    class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-prev"
                    @click="prev_clicked"
                    v-if="is_previous_visible()"
                  >
                  {{$t('PAGES.REGISTRATION.PREV_STEP')}}
                  </button>
                </div>
                <div>
                  
                  <button
                    v-if="!isLastTask"
                    class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                    @click="next_step_clicked"
                  >
                    <!--                    data-wizard-type="action-next"-->
                    {{$t('PAGES.REGISTRATION.NEXT_STEP')}}
                  </button>
                  <p v-if="display_error" class="error">{{ $t('TODO.'+display_error) }}</p>
                </div>
              </div>
              <!--end: Wizard Actions -->
            </form>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/sass/pages/wizard/wizard-3.scss';

.error { 
  font-weight: bold;
  color: #ed3737;
  margin-top: 8px;
}

@import "@/assets/sass/components/forms/_memlist_checkbox.scss";

</style>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import TodoWizardPage from '@/view/pages/ml/todowizard/TodoWizardPage.vue';
import IntroPage from '@/view/pages/ml/todowizard/IntroPage';

import KTUtil from '@/assets/js/components/util';
import KTWizard from '@/assets/js/components/wizard';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  name: 'todowizard',
  components: {
    TodoWizardPage,
    IntroPage,
    
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId']),
    isLastTask() {
      
      return this.wizard ? this.wizard.getStep() === this.pages.length + 1 : false;
    },
    isIntro() {
      return this.wizard ? this.wizard.getStep() === 1 : false;
    },
    current_task_index() {
      return this.wizard ? this.wizard.getStep() -2 : -1;
    }
  },
  mounted: function() {
    this.loadData();
  },
  data() {
    return {

      current_task: {},

      display_error: null,

      current_task_step: 0,

      disabledNextButton: true,
      form: {},
      showNext: true,
      currentPage: 0,
      wizard: null,
      order: {},
      pages: [],
      todo: null,
      task: null,
      todo_id: null,
      showStepModal: false
    };
  },
  watch: {
    showNext(newVal, oldVal) {
    
    }
  },
  methods: {

    prev_clicked() {
      this.display_error = false;
    },

    task_updated(task) {
      this.todo.tasks[this.current_task_index] = { ...task };

      this.current_task = { ...task };

      this.display_error = null;
    },

    fileUploaded(file) {
      
    },
    is_previous_visible() {
      return !this.isLastTask;
    },
    

    scroll_to_top() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },

    next_step_clicked() {

      if (this.current_task_index < 0 || this.current_task_index >= this.todo.tasks.length) {
        // this is intro and last page
      }
      else {
        this.current_task = this.todo.tasks[this.current_task_index];

        if (this.current_task.status !== 'DONE') {
          this.display_error = 'WIZARD_NOT_DONE_STATUS';
          return;
        }
        
        this.display_error = null;
      }

      
      const status = ['CLOSE'];
      
      const currentStatus = this.pages[this.wizard.currentStep - 1].task.status;
      
      if (status.includes(currentStatus)) {
        this.showStepModal = true;
      } else {
        this.nextPage();

        this.scroll_to_top();
      }
    },
    resumePage(e) {
      this.showStepModal = false;
      this.wizard.resume();
    },
    nextPage(e) {
      this.showStepModal = false;
      // make sure that the next button is properly disabled when clicking on next
      
      this.wizard.goNext();
      // this.currentPage = this.wizard.currentStep-1;
    },

    isValid(valid, index) {
      

      var currentStep = 0;

      if (this.wizard !== undefined && this.wizard !== null) {
        currentStep = this.wizard.getStep() - 1;
      }

      if (index == currentStep) {
        this.showNext = valid;
      } 

      if (this.pages.length > index) {
        this.pages[index].valid = valid;
      }
    },
    finish() {
      axios
        .put(`/todo/${this.todo_id}`, { status: 'DONE', todo_id: this.todo_id })
        .then(res => {
          if (res.status === 200) {
            this.toastr('success', this.$t('COMMON.OK'), this.$t('COMMON.UPDATED'));
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_UPDATE'));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_UPDATE'));
        });
    },
    createWizard() {
      var that = this;

      that.showNext = true;

      // Initialize form wizard
      that.wizard = new KTWizard('kt_wizard_v3', {
        startStep: 1, // initial active step number
        clickableSteps: true // allow step clicking
      });

      // Validation before going to next page
      that.wizard.on('beforeNext', function(/*wizardObj*/) {
        // validate the form and use below function to stop the wizard's step
        // wizardObj.stop();
      });

      // Change event
      that.wizard.on('change', function(/*wizardObj*/) {
        setTimeout(() => {
          KTUtil.scrollTop();

          that.currentPage = that.wizard.currentStep;

        }, 200);
      });

      that.showNext = false;
    },
    async loadData() {
      this.todo_id = this.$route.params.id;

      axios
        .get(`/todo/${this.todo_id}`)
        .then(res => {
          this.todo = res.data;

          
          this.pages = [];

          this.pages.push({
            type: 'intro',
            state: 'current',
            name: this.todo.name,
            index: 0,
            task: { name: this.todo.name, descr: this.todo.descr }
          });

          for (var i = 0; i < this.todo.tasks.length; ++i) {
            var task = this.todo.tasks[i];
            task.index = i;
            this.pages.push({
              type: 'normal',
              state: 'pending',
              name: task.name,
              index: i+1,
              task: task
            });
          }

          var that = this;

          setTimeout(function() {
            that.createWizard();
          }, 100);
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TODO.UNABLE_GET_WIZARD'));
        });
    },
    
  }
};
</script>
