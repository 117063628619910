<template>
  <div>
    <div class="card card-custom card-stretch">

      <div class="card-body pt-3 pb-3">
        <h5 class="mb-4 mt-4">{{ local_task.name }}</h5>

        <p class="mb-8">{{ local_task.descr }}</p>

        <FileTable
          v-if="local_task.type === 'FILE_WIZARD'"
          :files="local_task.files"
          :taskStatus="local_task.status"
          @onUploadFileClicked="selectFile"
          @downloadFileClicked="downloadFileClicked"
          @deleteFileClicked="deleteFileClicked"
        />

        <input
          hidden
          type="file"
          id="file"
          ref="file"
          v-on:change="handleFileUpload()"
          :accept="getExtensions()"
        />

        <ChatBox :comments="comments" />

        <b-row align-h="between" class="px-8">
          <b-col>
            <b-form-group id="input-group-text" label="Skriv meddelande" label-for="input-text">
              <b-textarea id="input-text" v-model="comment" class="mb-2"> </b-textarea>
            </b-form-group>
          </b-col>
          <b-col md="auto">
            <b-button
              type="button"
              style="margin-top: 26px; height: 55px; width: 120px;"
              variant="primary"
              @click="sendCommentClicked"
              >{{ $t('COMMON.SEND') }}</b-button
            >
          </b-col>
        </b-row>
        <b-row class="px-8">
          <b-col>
            <p class="mt-4 mb-4" style="font-style: bold;">{{ $t('TODO.CONFIRM_STATUS_TASK') }}</p>
            <label class="radio radio-outline radio-success mb-3">
              <input
                type="radio"
                v-model="local_task.status"
                value="DONE"

              />
              <span class="mr-2"></span>
              {{ $t('TODO.I_AM_FINISHED_WITH_TASK') }}
            </label>
            <label class="radio radio-outline radio-success mr-5">
              <input
                type="radio"
                v-model="local_task.status"
                value="NOT"

                />
              <span class="mr-2"></span>
              {{ $t('TODO.I_AM_NOT_FINISHED_WITH_TASK') }}
            </label>
          </b-col>
        </b-row>
      </div>
      <!--end::Body-->
    </div>
  </div>
</template>
<style lang="scss">
.checkbox > span {
  background-color: white;
  border: 1px solid #ddd;
}
</style>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import ChatBox from '@/view/components/chatBox.vue';
import FileTable from '@/view/pages/ml/todowizard/FileTable.vue';
import dayjs from 'dayjs';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'todo-wizard-page',
  props: ['title', 'page', 'todo', 'task'],
  emits: ['updated'],
  mixins: [ toasts ],
  components: {
    ChatBox,
    FileTable
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId'])
  },
  watch: {
    'local_task.status'() {
      this.status_updated();
    },
    task: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_task = { ...this.task };

        this.$emit('updated', this.local_task);

        this.loadComments();
      }
    },
  },
  data() {
    return {

      local_task: {},

      acceptLookups: {
        'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xslx, .xls',
        'png': 'image/png',
        'jpg': 'image/jpg',
        'pdf': '.pdf',
        'txt': 'text/plain',
        'doc': 'application/msword, .doc, .docx',
        'zip': '.zip'
      },

      comment: '',

      comments: [],


    };
  },
  mounted() {
    if (this.task) {
      this.local_task = { ...this.task };

      this.$emit('updated', this.local_task);

      this.loadComments();
    }

  },
  methods: {
    async status_updated() {

      const res = await axios.put(`/todo_task/${this.local_task.todo_task_id}`, { status: this.local_task.status, todo_task_id: this.local_task.todo_task_id });

      if (res.status === 200 || res.status === 204) {
        console.log('ok');
      }
      else {
        console.error('failed')
      }

      this.$emit('updated', this.local_task);
    },
    downloadFileClicked(file_id) {
      axios
        .get(`/file/${file_id}`)
        .then(res => {
          if (res.status === 200) {
            downloadWithAxios(get_base_url() + `/dlfile/${res.data.linkstr}`, res.data.name);
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta filen');
        });
    },

    deleteFileClicked(file_id) {
      axios
        .delete(`/file/${file_id}&todo_task_id=${this.local_task.todo_task_id}`)
        .then(res => {
          this.local_task.files = this.local_task.files.filter(file => file.file_id !== file_id);

          this.$emit('updated', this.local_task);
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte radera filen');
        });
    },
    loadComments() {
      axios
        .get(`/todo_task/comments/${this.local_task.todo_task_id}`)
        .then(res => {
          this.comments = res.data;
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta kommentarer');
        });
    },
    sendCommentClicked() {
      axios
        .post(`/todo_task/comment/${this.local_task.todo_task_id}`, {
          from: 'sub',
          comment: this.comment,
          todo_task_id: this.local_task.todo_task_id
        })
        .then(res => {
          this.comment = '';
          this.comments.push(res.data);
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte skicka kommentar');
        });
    },
    getExtensions() {
      let tempExtension = '';
      if (this.local_task.valid_filetypes) {
        tempExtension = this.local_task.valid_filetypes.map(x => `${this.acceptLookups[x]}`).join(', ');
      }
      return tempExtension;
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];

      if (this.file.size > (50000000)) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.MUST_BE_LESS'));
        return;
      }

      this.uploadFile();
    },
    selectFile() {
      this.$refs.file.value = null;
      this.$refs.file.click();
    },
    uploadFile() {
      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('file_source', 'todo');
      formData.append('file_type', this.local_task.category);
      formData.append('company_id', this.currentCompanyId);
      formData.append('todo_id', this.local_task.todo_id);
      formData.append('todo_task_id', this.local_task.todo_task_id);

      axios
        .post(
          `/fileupload?todo=true&todo_id=${this.local_task.todo_id}&todo_task_id=${this.local_task.todo_task_id}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
        .then(res => {
          if (res.status === 201) {
            this.toastr('success', this.$t('COMMON.OK'), 'Filen laddades upp');
            this.file_id = res.data.fileobjs[0].file_id;
            this.file = res.data.fileobjs[0];
            this.file.created_at = dayjs().format('YYYY-MM-DD HH:mm:ss');

            this.local_task.files.push(this.file);

            this.$emit('updated', this.local_task);
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte skapa fil');
          }

        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte ladda upp fil');
        });
    },

    resetForm() {
      this.comment = '';
      this.comments = [];

      this.status = '';
    },

  }
};
</script>
